import React from 'react';
import './index.css';
import topseperator from './componentns/top-01.svg';

const Top = () => {
    return (
        <>
            <img src={topseperator} className="border-separator" alt="Top Seperator" />
        </>
    )
}

export default Top;