import React, { useState } from 'react';
import './index.css';
import Navbar from './Navbar';
import Footer from './Footer';

const Roadmap = () => {
    const [showContent1, setShowContent1] = useState(false);
    const [showContent2, setShowContent2] = useState(false);
    const [showContent3, setShowContent3] = useState(false);
    const [showContent4, setShowContent4] = useState(false);
    const [showContent5, setShowContent5] = useState(false);
    const [showContent6, setShowContent6] = useState(false);
    const [showContent7, setShowContent7] = useState(false);

    const toggleContent1 = () => {
        setShowContent1(!showContent1);
    };

    const toggleContent2 = () => {
        setShowContent2(!showContent2);
    };

    const toggleContent3 = () => {
        setShowContent3(!showContent3);
    };

    const toggleContent4 = () => {
        setShowContent4(!showContent4);
    };

    const toggleContent5 = () => {
        setShowContent5(!showContent5);
    };

    const toggleContent6 = () => {
        setShowContent6(!showContent6);
    };

    const toggleContent7 = () => {
        setShowContent7(!showContent7);
    };

    return (
        <>
            <Navbar />
            <section id="roadmap">
                <div className='roadmap-cnt'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 col-sm-12 col-lg-12 timeline">
                            <div className="mx-auto" style={{ textAlign: 'center' }}>
                                <h4 className="subtitle-2" data-aos="zoom-in">
                                    <span className="theme-gradient" style={{ textAlign: 'center' }}>ROADMAP</span>
                                </h4>
                            </div>
                            <p className="touch-head-p mx-auto" style={{ textAlign: 'center' }} data-aos="zoom-in">
                                OUR ROADMAP
                            </p>
                            <p className="roadmap-p" data-aos="zoom-in">
                            Our roadmap outlines a journey of discovery and transformation, markedby key milestones and phases of development.
                            </p>

                            <ul>
                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent1}>
                                        <time>
                                        Q3 2024  <br />
                                            <span className='roadmap-header-p'> Foundation
                                                
                                            </span><i className={`fa fa-angle-${showContent1 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent1 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            Neurolov Grid Network: <span className='roadmap-content'>
                                            Launch the beta version of the Neurolov grid network, enabling distributed computing.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            GPU Renting platform: <span className='roadmap-content'>
                                            Release the initial version of the GPU compute rentingDapp with 1000+ GPUs available for public to rent.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Token Generation Event (TGE): <span className='roadmap-content'>
                                            Conduct the Token Generation Event to mint anddistribute the initial supply of $NLOV tokens.
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>
                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent2}>
                                        <time>
                                        Q1 2025 <br />
                                            <span className='roadmap-header-p'> GPU Renting DApp
                                                
                                            </span><i className={`fa fa-angle-${showContent2 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent2 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            LLM Integration : <span className='roadmap-content'>
                                            Implement initial integration with existing LLMs, setting upthefoundation for custom LLM development.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Staking and Rewards Program : <span className='roadmap-content'>
                                            Launch the staking and rewards program, incentivizing both long-term holding and GPU compute contributors.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Liquidity Pools : <span className='roadmap-content'>
                                            Establish initial liquidity pools on major decentralized exchanges
                                            (DEXs) to ensure token liquidity and facilitate trading.
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>

                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent3}>
                                        <time >
                                        Q2 2025 <br />
                                            <span className='roadmap-header-p'> Large-Scale Compute
                                                
                                            </span><i className={`fa fa-angle-${showContent3 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent3 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            Large-Scale Capabilities: <span className='roadmap-content'>
                                            Release of large scale GPU compute with almost 12000+GPUs available for public to use.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Advanced AI Modules: <span className='roadmap-content'>
                                            Release additional AI modules for data pre-processing, model training, and evaluation, tailored for LLM development.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Governance Platform: <span className='roadmap-content'>
                                            Launch the governance platform, allowing token holders toparticipate in decisions related to development priorities and overall platformdirection.
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>

                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent4}>
                                        <time>
                                        Q3 2025 <br />
                                            <span className='roadmap-header-p'> Scalable GPUs
                                                
                                            </span><i className={`fa fa-angle-${showContent4 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent4 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            Distributed Computing Framework : <span className='roadmap-content'>
                                            Implement Kubernetes and or Mesos for
                                            improved task scheduling and resource management, optimizing training processes.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Data Storage Integration: <span className='roadmap-content'>
                                            Integrate with additional databases like PostgreSQLandMongoDB, optimized for storing and retrieving large language models and trainingdata.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Advanced Analytic Tools: <span className='roadmap-content'>
                                            Introduce advanced analytic tools for users to better
                                            understand their staking rewards, liquidity pool performance, and LLMtrainingmetrics.
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>

                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent5}>
                                        <time >
                                        Q4 2025 <br />
                                            <span className='roadmap-header-p'> Global Expansion
                                                
                                            </span><i className={`fa fa-angle-${showContent5 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent5 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            Cross-Chain Compatibility : <span className='roadmap-content'>
                                            Develop cross-chain compatibility to allowNeurolovNetwork and $NLOV tokens to interact with other blockchain networks.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Mobile App for Interaction : <span className='roadmap-content'>
                                            Develop and release a mobile app allowing users tointeract with and manage their GPU projects and access other platformfeatures onthego.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Continuous LLM Innovation : <span className='roadmap-content'>
                                            Based on user feedback and advancements in the field, continuously improve LLM training capabilities, model performance, and overall
                                            platform features. 
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>


                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent6}>
                                        <time >
                                        Q1 2026 <br />
                                            <span className='roadmap-header-p'> Decentralized LLMs
                                                
                                            </span><i className={`fa fa-angle-${showContent6 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent6 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            AI-Powered DeFi Integrations : <span className='roadmap-content'>
                                            Integrate Neurolov's LLMs with major DeFi
                                            platforms to offer AI-enhanced financial instruments such as lending, borrowing, andyield farming.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Token Utility Expansion : <span className='roadmap-content'>
                                            Implement new token utility features focused on accessingand utilizing advanced LLM capabilities, alongside token burn mechanisms tomanage supply.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            Full Decentralization of LLM Network : <span className='roadmap-content'>
                                            Transition towards full decentralizationof
                                            the LLM training and deployment network, empowering the community to governand sustain Neurolov's ecosystem.
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>


                                <li className="in-view">
                                    <div data-aos="fade-right" onClick={toggleContent7}>
                                        <time >
                                        Q3 2026<br />
                                            <span className='roadmap-header-p'> AGI Research
                                                
                                            </span> <i className={`fa fa-angle-${showContent7 ? 'up' : 'down'} toggle-content`} aria-hidden="true"></i>
                                        </time>

                                        <span className={showContent7 ? 'more-content' : 'more-content hidden'}>
                                            <p className='roadmap-content-border'>
                                            AGI Research Program : <span className='roadmap-content'>
                                            Launch a dedicated research program focused on Artificial
                                            General Intelligence (AGI), leveraging Neurolov's advanced LLMcapabilities anddistributed compute network.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            AGI Development Framework : <span className='roadmap-content'>
                                            Begin development of a framework for AGI
                                            experimentation and testing within the Neurolov ecosystem.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            AGI Collaboration Network : <span className='roadmap-content'>
                                            Create a collaborative network for AGI researchers
                                            and developers to share insights and resources within the Neurolov platform.
                                                </span>
                                            </p>
                                            <p className='roadmap-content-border'>
                                            AGI Compute Allocation : <span className='roadmap-content'>
                                            Dedicate a significant portion of the GPUcomputenetwork to AGI-focused tasks and experiments.
                                                </span>
                                            </p>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <p className="roadmap-p-footer roadmap-p" data-aos="zoom-in">
                        Our commitment to innovation, integrity, and excellence guides us as wenavigate the complexities of this ever-evolving landscape. Whilewestrive for progress and transformation, it's important to recognize that thepath ahead is not without challenges or uncertainties. The dynamic natureof technology means that changes can occur, unforeseen obstacles mayarise, and adaptations may be necessary. Yet, it is precisely inthesemoments of uncertainty that opportunity lies —opportunity to learn, togrow, and to evolve. Thank you for joining us on this incredible journey. The future awaits, and together, we will shape it —one breakthroughat atime.
                            </p>
                    </div>
                </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Roadmap;