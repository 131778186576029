import React, { useState, useRef } from 'react';
import './index.css';
import value from './componentns/Neuro2.png';
import video from './componentns/video.mp4';
import Navbar from './Navbar';
import Top from './Top';
import Bottom from './Bottom';
import Footer from './Footer';
import why from './componentns/why.png';
import seperator from './componentns/top-01.svg';
import NeurolovVid from './componentns/Neurolov.mp4'
import GPU from './componentns/GPU.mp4'
import first from './componentns/first.png'
import second from './componentns/second.png'

const Neurolov = () => {
        const textRef = useRef(null);
      
        const copyToClipboard = () => {
          if (textRef.current) {
            const text = textRef.current.textContent;
            navigator.clipboard.writeText(text).then(() => {
              alert('Copied to clipboard!');
            }).catch(err => {
              console.error('Failed to copy text: ', err);
            });
          }
        };

    return (
        <>
            <video autoPlay muted loop id="background-video">
                <source src={video} type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>
            <div className="overlay"></div>
            <Navbar />
            <section id="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-12 col-sm-12 col-lg-1">

                        </div>
                        <div className="col-md-10 col-12 col-sm-12 col-lg-10">
                            <div className='content'>
                                <h1 className="banner-h1" data-aos="zoom-in">
                                    <span className="gradient-text">AI meets WEB3</span>
                                </h1>

                                <p className="banner-p" data-aos="zoom-in">
                                Revolutionizing the future with GPU-powered AI on Solana Network.
                                </p>
                                <div className="buttons" data-aos="zoom-in">
                                    <a href="https://discord.gg/RfBDQUarvv" className="gradient-btn node-btn ">
                                        Join the Future
                                    </a>
                                    
                                </div>

                                <section data-aos="fade-up" id="mytimer" >
			<div class="container" >
				<div class="row timer-row" >
					
					<div class="col-md-5 col-sm-12 col-12 col-lg-5 timer-left">
						<h2 class="timer-h2">
Next Price Increase In						</h2>
					

<a href="https://logwork.com/countdown-s54s" class="countdown-timer" data-timezone="Asia/Kolkata" data-textcolor="#101123" data-date="2024-07-17 17:30" data-background="#101123" data-digitscolor="#fff" data-unitscolor="#fff">ICO Starting in</a>
						
					</div>
					<div class="col-md-7 col-sm-12 col-12 col-lg-7 timer-right">
						<h1 class="mytimer-h1">
							Click here to get <a href="https://gleam.io/C3fCa/pimini-ico-whitelist" class="white-paper-btn" >Whitelisted</a>
						</h1>
						
                        <div className='presale-content'>
                        {/* <p className='buy-p'>
                            USDT RAISED: $709,234,543/$3,02,321
                        </p> */}

                        <span class="mut">Contract Address : </span><span id="p1" class="contract-span" onClick={copyToClipboard} 
        ref={textRef}>0x53D28A23fA251583B03D8028CdDb7354Cd1F3AC6<i  class="fa fa-clone"></i></span>
                        </div>

                        <div class="row indicator-row">
							<div class="col-md-4 col-sm-4 col-4 col-lg-4">
								<span class="indicator-p">Current Progress</span>
							</div>
							
							<div class="col-md-4 col-sm-4 col-4 col-lg-4">
								
							</div>
							<div class="col-md-4 col-sm-4 col-4 col-lg-4" style={{textAlign: 'right'}}>
								{/* <span class="indicator-p">500 BNB HC</span> */}
							</div>
						</div>

                        <div class="progress">
							<div class="progress-bar" role="progressbar" style={{width: '40%'}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
						  </div>

                          <p className='buy-p'>
                            Token Name: $Neurolov <br/>Presale Supply: 100,000,000 <br/>Presale Price: 1 BNB = 5,000
                            </p>
                       
                            <form action="" method='POST'>
                      
    <input type="email" class="form-control buy-form" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount of Token"/>
    
                            </form>

                            <a href="" className="gradient-btn node-btn learn-more">
                                        Buy Token
                                    </a>
						
					
					</div>
				</div>
				
			
			</div>
		</section>
                            </div>

                        </div>
                        <div className="col-md-1 col-12 col-sm-12 col-lg-1">

                        </div>
                    </div>
                </div>

                {/* <img src="../static/Images/gpu.png" className="gpu-img"> */}
            </section>


            <img src={seperator} className="first-one" alt="Top Seperator" />

            <section id="value-proposition">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-12 col-sm-12 col-lg-2">

                        </div>
                        <div className="col-md-8 col-12 col-sm-12 col-lg-8">
                            <div className="mx-auto" style={{ textAlign: 'center' }}>
                                <h4 className="subtitle-2 about-subtitle" data-aos="fade-up">
                                    <span className="theme-gradient" style={{ textAlign: 'center' }}>Built on Solana</span>
                                </h4>
                            </div>
                            <p className="touch-head-p mx-auto" style={{ textAlign: 'center' }} data-aos="fade-up">
                            Neurolov Platform                            </p>
                            {/* <p className="subtitle" data-aos="zoom-in">
                                Unlock the Potential of AGI in the Metaverse
                            </p> */}
                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-2">

                        </div>
                    </div>


                    <div className="row value-proposition-2">
                        <div className="col-md-6 col-12 col-sm-12 col-lg-6" data-aos="fade-up">
                            <img src={first} className="value-proposition" alt="Value Proposition" />
                            
                            {/* <video autoPlay muted loop className='value-proposition'>
                                <source src={GPU} type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video> */}
                        </div>
                        {/* <div className="col-md-6 col-12 col-sm-12 col-lg-6 value-proposition-col-2" data-aos="fade-left">
                            <h1 className="value-proposition-h1">
                                Pioneering AGI through high-performance compute
                            </h1>
                            <p className="value-proposition-p">
                                Neurolov is designed with a comprehensive set of features that leverage advanced technologies
                                to provide a robust and user-friendly platform for AI development and deployment. Here are
                                some key features:-
                            </p>
                            <ul className="value-proposition-ul">
                                <li>
                                    User-Friendly GPU Renting Interface
                                </li>
                                <li>
                                    Secure Payment Systems
                                </li>
                                <li>
                                    Efficient Resource Management
                                </li>
                                <li>
                                    High-Capacity Data Storage Solutions
                                </li>
                                <li>
                                    Collaboration Tools for AI Development
                                </li>
                            </ul>
                            <a href="#" className="gradient-btn node-btn learn-more">
                                Learn More
                            </a>
                        </div> */}
                        <div className="col-md-12 col-12 col-sm-12 col-lg-6 value-proposition-col-2" data-aos="fade-up">
                            {/* <h1 className="value-proposition-h1">
                                Blockchain GPU Sharing for AI
                            </h1> */}
                            <p className="value-proposition-p mobile-p">
                            The platform harnesses the power of Web3 technologies, implementingadecentralized neural network of idle GPU resources to democratize access tohigh-performance computing. By utilizing advanced machine learningalgorithms, quantum-resistant cryptography, and AI-driven resource
allocation, Neurolov delivers an unparalleled, scalable solution for next- generation LLM development and rendering tasks. Our proprietary consensus mechanism, coupled with state-of-the-art
homomorphic encryption, ensures seamless interoperability and ironcladsecurity across the ecosystem. This disruptive technology stack, combinedwith our tokenomics-driven incentive model, positions Neurolov at the vanguard of the decentralized AI revolution, poised to capture significant market share.
                            </p>

                            

                            <div>
                                <div className='learn-more-btn'>
                                    <a href="https://wiki.neurolov.ai/nlov/architecture" className="gradient-btn node-btn learn-more">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Bottom />

            <section id="touch">
                <div className="container">
                    <div className="mx-auto" style={{ textAlign: 'center' }}>
                        <h4 className="subtitle-2" data-aos="fade-up">
                            <span className="theme-gradient" style={{ textAlign: 'center' }}>NEUROLOV NETWORK</span>
                        </h4>
                    </div>
                    <p className="touch-head-p mx-auto" style={{ textAlign: 'center' }} data-aos="fade-up">
                    Core Stack
                    </p>
                    <p className="subtitle" data-aos="fade-up">
                    The unique combination of decentralized architecture, blockchaintechnology, and AI-driven optimization creates a powerful ecosystem benefits all stakeholders – from individual GPU owners to large-scaleAI
researchers and developers. 
                    </p>
                    <div className="row touch-col-row">

                        <div className="col-md-12 col-12 col-sm-12 col-lg-4 touch-col" data-aos="fade-up">
                            <h3 className="touch-h3">
                            AI Model Hub 
                            </h3>
                            <p className="touch-p">
                            Marketplace for AI Models
                            </p>
                        </div>
                        <div className="col-md-12 col-12 col-sm-12 col-lg-4 touch-col touch-col-2" data-aos="fade-up">
                            <h3 className="touch-h3">
                            Web3 Computing 
                            </h3>
                            <p className="touch-p">
                            High performance compute over web3
                            </p>
                            
                        </div>
                        <div className="col-md-12 col-12 col-sm-12 col-lg-4 touch-col touch-col-3" data-aos="fade-up">
                            <h3 className="touch-h3">
                                AGI Research
                            </h3>
                            <p className="touch-p">
                                AGI Research plan
                            </p>
                            
                        </div>

                      
                    </div>
                    <div className='doc-btn' data-aos="fade-up">
                                    <a href="https://wiki.neurolov.ai/nlov/gpu-allocation" className="gradient-btn node-btn learn-more">
                                        Docs
                                    </a>
                                </div>
                </div>
            </section>

            <Top />

            <section id="discover">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-12 col-sm-12 col-lg-2">

                        </div>
                        <div className="col-md-8 col-12 col-sm-12 col-lg-8">
                            <div className="mx-auto" style={{ textAlign: 'center' }}>
                                <h4 className="subtitle-2 about-subtitle" data-aos="fade-up">
                                    <span className="theme-gradient" style={{ textAlign: 'center' }}>OUR FEATURES</span>
                                </h4>
                            </div>
                            <p className="touch-head-p mx-auto" style={{ textAlign: 'center' }} data-aos="fade-up">
                            OUR FEATURES
                            </p>

                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-2">

                        </div>
                    </div>

                    <div className="row value-proposition-2 second-value-proposition">
                    <div className="col-md-12 col-12 col-sm-12 col-lg-6 mobile" data-aos="fade-up">
                            {/* <img src={why} className="value-proposition second-img" alt="Value Proposition" /> */}
                            <video autoPlay muted loop className='value-proposition'>
                                <source src={NeurolovVid} type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="col-md-12 col-12 col-sm-12 col-lg-6 value-proposition-col-2 why-neurolov" data-aos="fade-up">
                            <h1 className="value-proposition-h1 why-h1">
                            Redefining the Future of Computing
                            </h1>
                            <p className="value-proposition-p last-p mobile-p">
                            Neurolov is designed with a comprehensive set of features that leverageadvanced technologies to provide a robust and user-friendly platformfor AI:-
                            </p>
                            <ul className="value-proposition-ul">
                                <li>
                                Development and Deployment
                                </li>
                                <li>
                                AI Model Training Hub
                                </li>
                                <li>
                                Federated Learning Implementation
                                </li>
                                <li>
                                Dynamic Pricing Model
                                </li>
                                <li>
                                Multi-GPU Training Capabilities
                                </li>
                                <li>
                                AI-powered resource allocation
                                </li>
                                
                            </ul>
                            <a href="https://wiki.neurolov.ai/nlov/ai-and-llm" className="gradient-btn node-btn learn-more">
                                Learn More
                            </a>
                        </div>

                        <div className="col-md-12 col-12 col-sm-12 col-lg-6 desktop" data-aos="fade-up">
                            <img src={second} className="value-proposition second-img" alt="Value Proposition" />
                            {/* <video autoPlay muted loop className='value-proposition'>
                                <source src={NeurolovVid} type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video> */}
                        </div>

                    </div>
                </div>
            </section>



            <a href="#navbar"><i className="fa fa-angle-up footer-up" aria-hidden="true"></i></a>

            <Footer />
        </>
    )
}

export default Neurolov;