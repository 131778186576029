import React, { useState } from 'react';
import './index.css';
import Navbar from './Navbar';
import Footer from './Footer';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const FAQ = () => {
    const [visibleAnswers, setVisibleAnswers] = useState({
        a1: false,
        a2: false,
        a3: false,
        a4: false,
        a5: false,
        a6: false,
        a7: false,
        a8: false,
        a9: false,
        a10: false,
        a11: false,
        a12: false,
        a13: false,
        a14: false,
        a15: false,
        a16: false,
        a17: false,
        a18: false,
       
    });

    const toggleAnswer = (key) => {
        setVisibleAnswers((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };
    return (
        <>
            <Navbar />
            <section id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 col-sm-12 col-lg-2">

                        </div>
                        <div className="col-md-12 col-12 col-sm-12 col-lg-8">
                            <div className="mx-auto" style={{ textAlign: 'center' }}>
                                <h4 className="subtitle-2" data-aos="zoom-in">
                                    <span className="theme-gradient" style={{ textAlign: 'center' }}>Accordion</span>
                                </h4>
                                <p className="touch-head-p mx-auto" style={{ textAlign: 'center' }} data-aos="zoom-in">
                                    Frequently Asked Questions
                                </p>

                                <p className="subtitle faq-subtitle" data-aos="fade-up">
                                    Welcome to our FAQ section—your one-stop destination for answers to all your burning
                                    questions!
                                </p>


                                <h3 id="q1" onClick={() => toggleAnswer('a1')} className="question mt-5" data-aos="fade-up">
                                    <i id="first" className={`fa ${visibleAnswers.a1 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What is Neurolov?

                                    {visibleAnswers.a1 && <p id="a1" className="answer">
                                        Neurolov is an innovative project that merges the power of blockchain technology with advanced artificial intelligence to create a decentralized platform for renting and utilizing GPU resources. It aims to democratize access to high-performance computing for AI development and research.</p>}

                                </h3>

                                <h3 id="q2" onClick={() => toggleAnswer('a2')} className="question" data-aos="fade-up">
                                    <i id="second" className={`fa ${visibleAnswers.a2 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    How does the GPU Renting Program work?

                                    {visibleAnswers.a2 && <p id="a2" className="answer">The GPU Renting Program allows GPU owners to lease their idle computing power to users who need it for AI tasks. Renters can access these resources through a decentralized marketplace, paying with NLOV tokens. Smart contracts ensure fair compensation and resource allocation.</p>}

                                </h3>

                                <h3 id="q3" onClick={() => toggleAnswer('a3')} className="question" data-aos="fade-up">
                                    <i id="third" className={`fa ${visibleAnswers.a3 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What is the Distributed Computing Framework (DCF)?

                                    {visibleAnswers.a3 && <p id="a3" className="answer">The DCF leverages the combined power of multiple rented GPUs across a distributednetwork, providing scalable and efficient computational solutions. It ensures secure, decentralized coordination of resources through blockchain.</p>}

                                </h3>

                                <h3 id="q4" onClick={() => toggleAnswer('a4')} className="question" data-aos="fade-up">
                                    <i id="fourth" className={`fa ${visibleAnswers.a4 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    How do Advanced AI Training Modules (AITM) enhance AI development?

                                    {visibleAnswers.a4 && <p id="a4" className="answer">To participate in the pre-sale, AITM focuses on deep learning, reinforcement learning, and other AI methodologies
                                        essential for AGI. These modules utilize distributed computing power to develop scalable, adaptable AI algorithms and systems.</p>}

                                </h3>

                                <h3 id="q4" onClick={() => toggleAnswer('a4')} className="question" data-aos="fade-up">
                                    <i id="fourth" className={`fa ${visibleAnswers.a4 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What are the key features of Neurolov’s Data Storage and Access (DSA) solutions?

                                    {visibleAnswers.a4 && <p id="a4" className="answer">DSA provides high-capacity, high-speed storage solutions capable of handling large
                                        datasets quickly. It includes efficient data retrieval and management systems with
                                        decentralized storage for enhanced reliability and security.</p>}

                                </h3>

                                <h3 id="q5" onClick={() => toggleAnswer('a5')} className="question" data-aos="fade-up">
                                    <i id="fifth" className={`fa ${visibleAnswers.a5 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What makes Neurolov's AGI development unique?

                                    {visibleAnswers.a5 && <p id="a5" className="answer">Neurolov integrates GPU renting, distributed computing, advanced AI training, and
                                        blockchain technology to develop AGI that can perform a wide range of tasks with
                                        human-like cognitive abilities. The approach ensures scalability, security, and ethical
                                        development.</p>}

                                </h3>

                                <h3 id="q6" onClick={() => toggleAnswer('a6')} className="question" data-aos="fade-up">
                                    <i id="sixth" className={`fa ${visibleAnswers.a6 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What are the benefits of joining the Neurolov community?

                                    {visibleAnswers.a6 && <p id="a6" className="answer">Benefits include access to affordable GPU computing power, opportunities to earn by renting out GPUs, participation in governance decisions, potential token value appreciation, and being part of a cutting-edge AI and blockchain ecosystem.</p>}

                                </h3>

                                <h3 id="q7" onClick={() => toggleAnswer('a7')} className="question" data-aos="fade-up">
                                    <i id="seventh" className={`fa ${visibleAnswers.a7 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    How can I participate in Neurolov's governance?

                                    {visibleAnswers.a7 && <p id="a7" className="answer">
                                        Token holders can participate in governance by staking NLOV tokens, which grants voting rights. You can propose changes, vote on key decisions, and help shape the future of the platform through the decentralized autonomous organization (DAO).</p>}

                                </h3>

                                <h3 id="q8" onClick={() => toggleAnswer('a8')} className="question" data-aos="fade-up">
                                    <i id="eight" className={`fa ${visibleAnswers.a8 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What is the Neurolov token (NLOV)?

                                    {visibleAnswers.a8 && <p id="a8" className="answer">The Neurolov token (NLOV) is the native cryptocurrency of the Neurolov platform, facilitating transactions, rewarding participants, and enabling governance within the ecosystem.</p>}

                                </h3>

                                <h3 id="q9" onClick={() => toggleAnswer('a9')} className="question" data-aos="fade-up">
                                    <i id="nine" className={`fa ${visibleAnswers.a9 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    How can I acquire Neurolov tokens?

                                    {visibleAnswers.a9 && <p id="a9" className="answer">You can acquire NLOV tokens through the initial token sale, decentralized exchanges after listing, by participating in the GPU renting program, or through various community incentive programs.</p>}

                                </h3>

                                <h3 id="q10" onClick={() => toggleAnswer('a10')} className="question" data-aos="fade-up">
                                    <i id="ten" className={`fa ${visibleAnswers.a10 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What are the benefits of staking?

                                    {visibleAnswers.a9 && <p id="a10" className="answer"> Staking NLOV tokens provides several benefits, including earning passive income through staking rewards, increased voting power in governance decisions, and access to exclusive platform features or discounts on services.</p>}

                                </h3>

                                <h3 id="q11" onClick={() => toggleAnswer('a11')} className="question" data-aos="fade-up">
                                    <i id="eleven" className={`fa ${visibleAnswers.a11 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    When is the Neurolov token presale?

                                    {visibleAnswers.a9 && <p id="a11" className="answer">The Neurolov token presale is scheduled to begin on 20-07-2024. Please check our official website and social media channels for the most up-to-date information on the presale timing and participation instructions.
                                    </p>}

                                </h3>

                                <h3 id="q12" onClick={() => toggleAnswer('a12')} className="question" data-aos="fade-up">
                                    <i id="twelve" className={`fa ${visibleAnswers.a12 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What is the total supply of NLOV tokens?

                                    {visibleAnswers.a9 && <p id="a12" className="answer">The total supply of NLOV tokens is capped at 500 million. This fixed supply ensures scarcity and potential for value appreciation as the platform grows.
                                    </p>}

                                </h3>

                                <h3 id="q13" onClick={() => toggleAnswer('a13')} className="question" data-aos="fade-up">
                                    <i id="thirteen" className={`fa ${visibleAnswers.a13 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    Is there a vesting period for team and advisor tokens?

                                    {visibleAnswers.a9 && <p id="a13" className="answer">Yes, tokens allocated to the team and advisors are subject to a vesting period to ensure long-term commitment. The specific vesting schedule is outlined in our whitepaper.
                                    </p>}

                                </h3>

                                <h3 id="q14" onClick={() => toggleAnswer('a14')} className="question" data-aos="fade-up">
                                    <i id="fourteen" className={`fa ${visibleAnswers.a14 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    What blockchain is Neurolov built on?

                                    {visibleAnswers.a14 && <p id="a14" className="answer">Neurolov is built on the Solana blockchain, chosen for its high throughput, low transaction costs, and energy efficiency, which align with our goal of providing scalable AI computing solutions.
                                    </p>}

                                </h3>

                                <h3 id="q15" onClick={() => toggleAnswer('a15')} className="question" data-aos="fade-up">
                                    <i id="fifteen" className={`fa ${visibleAnswers.a15 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    How does Neurolov ensure the security of GPU transactions and AI model sharing?

                                    {visibleAnswers.a15 && <p id="a15" className="answer">Neurolov employs advanced cryptographic techniques, smart contract audits, and a decentralized network to ensure the security of transactions and data. We also implement strict verification processes for AI model uploads and usage.
                                    </p>}

                                </h3>


                                <h3 id="q16" onClick={() => toggleAnswer('a16')} className="question" data-aos="fade-up">
                                    <i id="sixteen" className={`fa ${visibleAnswers.a9 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    How does Neurolov differentiate itself from other blockchain-AI projects?

                                    {visibleAnswers.a16 && <p id="a16" className="answer">Neurolov stands out through its focus on democratizing high- performance GPU access, its innovative federated learning implementation, and its commitment to advancing AGI research in a decentralized manner.
                                    </p>}

                                </h3>

                                <h3 id="q17" onClick={() => toggleAnswer('a17')} className="question" data-aos="fade-up">
                                    <i id="seventeen" className={`fa ${visibleAnswers.a17 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                    Are there any lockup periods for presale participants?

                                    {visibleAnswers.a17 && <p id="a17" className="answer">Yes, there is a lockup period for presale participants to ensure project stability. The specific terms of the lockup will be clearly communicated during the presale process.
                                    </p>}

                                </h3>


                                <p className="subtitle faq-subtitle faq-subtitle-2" data-aos="fade-up">
                                If you have any more questions or need further assistance, please feel free to reach out to our
team. You can join our community on Discord where you can engage directly with our
developers and other community members. Alternatively, you can email us at <a href='maito:support@neurolov.io' className='faq-link'>support@neurolov.io</a>
, and we’ll be happy to assist you. Your participation and feedback are
crucial to our continuous improvement and success, so don’t hesitate to get in touch!
                                </p>
{/* 
                                <Link to="/FAQ#navbar" className="gradient-btn node-btn learn-more doubt" data-aos="zoom-in">
                                    Contact Us
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-2">

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default FAQ;